import { useLocation, useRoutes } from "react-router-dom";
import * as React from "react";
import HomeScreen from "./Screens/HomeScreen";
import { AnimatePresence } from "framer-motion";
import AboutusScreen from "./Screens/AboutusScreen";
import { LanguageProvider } from "./Context/LanguageContext";
import PortfolioScreen from "./Screens/PortfolioScreen";
import InsigthsScreen from "./Screens/InsigthsScreen";
import NoticeScreen from "./Screens/NoticeScreen";
import ContactScreen from "./Screens/ContactScreen";


function App() {

  const element = useRoutes([
    {
      path: "/",
      element: <HomeScreen />
    },
    {
      path: "/aboutus",
      element: <AboutusScreen />
    },
    {
      path: "/portfolio",
      element: <PortfolioScreen />
    },
    {
      path: "/insights",
      element: <InsigthsScreen />
    },
    {
      path: "/notice/*",
      element: <NoticeScreen />
    },
    {
      path: "/contact",
      element: <ContactScreen />
    },
    {
      path: "*",
      element: <HomeScreen />
    }

  ]);

  const location = useLocation();

  if (!element) return null;


  return (
   
      <AnimatePresence mode="wait" initial={true}>
        {React.cloneElement(element, { key: location.pathname })}
      </AnimatePresence>

  );
}

export default App;
